@import "vars";

header {
  .wrapper {
      display: flex;
      flex-wrap: wrap;
      .yellow-block {
        text-align: center;
        padding: 12px 0 20px 0;
        flex: 1 1 32%;
        background: $yellow-bg;
        h1 {
          font-size: 22px;
          line-height: 1;
          color: $red;
          margin-bottom: 12px;
          font-weight: 900;
          text-transform: uppercase; }
        a {
          color: $red;
          font-size: 30px;
          line-height: 1;
          font-weight: 900;
          &>span {
            color: $red;
            font-size: 18px;
            line-height: 1;
            font-weight: normal; } } }

      .blue-block {
        flex: 1 1 68%;
        background: #182139;
        position: relative;
        .menu-btn {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          display: none;
          line-height: 1;
          color: #fff;
          font-size: 18px;
          text-transform: uppercase;
          padding: 0 5px;
          width: 100%; }


        .wrap-menu {
          display: table;
          width: 100%;
          padding: 35px 0 30px 0;
          ul {
            display: table-row;
            li {
              display: table-cell;
              text-align: center;
              padding: 0 10px;
              a {
                display: block;
                line-height: 1;
                color: #fff;
                font-size: 18px;
                font-weight: normal;
                text-transform: uppercase;
                &:hover {
                  color: $yellow-bg; } } } } } } } }



@media screen and (max-width: 1080px) {
  .wrapper {
    .yellow-block {
      flex: 1 1 100%!important; }
    .blue-block {
      flex: 1 1 100%!important; } } }
@media screen and (max-width: 720px) {
  header {
    position: relative;
    .wrapper {
      .yellow-block {
        flex: 1 1 100%!important;
        padding: 12px 0;
        h1 {
          font-size: 18px; } }
      .blue-block {
        flex: 1 1 100%!important;
        .menu-btn {
          margin: auto;
          color: #fff; }
        .container {
          .wrap-menu {
            flex: 1 1 100%;
            ul.navigation {
              width: 100%;
              position: absolute;
              right: 0;
              top: 100%;
              z-index: 3;
              display: none;
              background: #182139;
              text-align: center;
              border: 1px solid #000;
              padding: 5px 10px;
              &.active {
                display: block; }
              li {
                display: inherit!important;
                padding: 15px 0; } } }
          .menu-btn {
            display: block; } } } } } }


//
