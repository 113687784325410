@import "vars";


//
.btn-yellow {
  border: 3px solid #182139;
  margin-bottom: 10px;
  height: 60px;
  overflow: hidden;
  background: #f9d000;
  color: #161616;
  font-size: 22px;
  margin-top: 20px;
  padding: 0 48px;
  text-transform: uppercase; }
.btn-white {
  border: 2px solid #182139;
  background: #ffffff;
  color: #161616;
  font-size: 18px;
  width: 100%;
  height: 55px;
  text-transform: uppercase;
  &:hover {
    background: #e1b200; } }
.btn-call-back {
  display: inline-block;
  border: 2px solid #ffffff;
  background: #182139;
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
  padding: 0 15px;
  height: 73px; }
.btn-car {
  text-transform: uppercase;
  display: inline-block;
  color: $red;
  font-size: 20px;
  padding: 0 70px;
  height: 62px;
  border: 2px solid #000000;
  margin: 0 auto;
  width: 48%; }
@media only screen and (max-width: 800px) {
  .btn-car {
    width: 100%;
    margin-bottom: 15px; } }


.slider {
  position: relative;
  .slick-next,
  .slick-prev {
    position: absolute;
    background: url("../img/reviews-section/arrow.png")no-repeat center;
    top: 0;
    right: 0;
    width: 25px;
    height: 35px;
    color: transparent;
    z-index: 3; }
  .slick-prev {
    right: initial;
    left: 40%;
    top: 25px; }
  .slick-next {
    left: initial;
    right: 43%;
    top: 25px;
    background: url("../img/reviews-section/arrow-yellow.png")no-repeat center; }
  .slick-next.active {
    background: red; } }

@media only screen and (max-width: 360px) {
  .slider {
    .slick-next {
      right: 23%; }
    .slick-prev {
      left: 20%; } } }
.select2-selection {
  width: 100%;
  .select2-selection--single {
    height: 100%; } }

.select2-container--default {
  .select2-selection--single {
    height: 60px;
    border-radius: inherit;
    padding: 18px 0 0 0; } }

.select2-container--default {
  .select2-selection--single {
    .select2-selection__rendered {
      font-size: 18px;
      color: #161616;
      line-height: 1; } } }



.select2-container--default {
  .select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow b {

        position: absolute;
        background: url("../img/bg/arrow.png") no-repeat center; } } } }

.select2-container--default {
  .select2-selection--single {
    .select2-selection__arrow b {
      position: absolute;
      background: url("../img/bg/arrow.png") no-repeat center;
      width: 16px;
      height: 20px;
      border-style: inherit;
      left: -14px;
      margin-left: 12px;
      top: 18px; } } }







