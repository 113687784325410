@import "vars";

/*--------------GLOBAL RESET-----------------*/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0; }

*:-moz-placeholder {
    color: #161616;
    font-size: 18px;
    opacity: 1; }

*::-moz-placeholder {
    color: #161616;
    font-size: 18px;
    opacity: 1; }

*::-webkit-input-placeholder {
    color: #161616;
    font-size: 18px;
    opacity: 1; }

*:-ms-input-placeholder {
    color: #161616;
    font-size: 18px;
    opacity: 1; }

*:focus::-webkit-input-placeholder {
    color: #161616;
    font-size: 18px; }
*:focus::-moz-placeholder {
    color: #161616;
    font-size: 18px; }
*:focus:-moz-placeholder {
    color: #161616;
    font-size: 18px; }
*:focus:-ms-input-placeholder {
    color: #161616;
    font-size: 18px; }
.slick-slider, .select2 {
    * {
        outline: 0; } }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn,
em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, menu, nav, output, ruby, section,
summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, menu, nav,
section, main {
    display: block; }

img {
    max-width: 100%;
    height: auto; }

ul.reset {
    list-style: none; }

.clearfix:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0; }

button {
    outline: 0;
    border: 0;
    display: inline-block;
    background: rgba(0, 0, 0, 0);
    cursor: pointer; }

input, textarea {
    outline: 0; }

input::-ms-clear {
    display: none;
    width: 0;
    height: 0; }

/*--------------END GLOBAL RESET-----------------*/

/*--------------CUSTOM RESET-----------------*/
html, body {
    font-size: 18px;
    line-height: 1;
    font-family: 'Roboto', sans-serif;
    position: relative; }

h2 {
    font-size: 35px;
    font-weight: bold;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase; }
a {
    text-decoration: none;
    transition: .3s ease all;
    &:hover {
        text-decoration: underline; } }


a[href^="tel:"] {
    &:hover {
        text-decoration: none; } }

.container {
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    overflow: hidden; }


/*-----------END CUSTOM RESET----------------*/
