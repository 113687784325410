@import "vars";

footer {
  background: #182139;
  padding: 70px 0 20px 0;
  position: relative;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .wrap-logo {
      flex: 1 1 60%;
      img {
        display: inline-block;
        vertical-align: middle;
        padding: 0 15px; }
      p {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 1.1;
        color: #ffffff; } }

    .wrap-call {
      flex: 1 1 40%;
      h3 {
        font-size: 35px;
        color: $red;
        line-height: 1.1;
        margin-bottom: 15px;
        font-weight: bold;
        text-transform: uppercase; }
      a {
        font-size: 38px;
        color: $red;
        line-height: 1.1;
        &>span {
          font-size: 18px;
          color: #aa0000;
          line-height: 1.1;
          font-weight: bold;
          text-transform: uppercase; } } } }
  .wrap-down {
    border-top: 1px solid $yellow-bg;
    p {

      color: #ffffff;
      line-height: 1.1;
      padding: 35px 0;
      margin: 0 auto;
      text-align: center; } } }


@media only screen and (max-width: 1000px) {
  footer {
    .wrapper {
      .wrap-logo {
        flex: 1 1 100%;
        text-align: center;
        margin: 0 auto 30px; }
      .wrap-call {
        flex: 1 1 100%;
        text-align: center;
        margin: 0 auto; } } } }
@media only screen and (max-width: 595px) {
  footer {
    .wrapper {
      padding: 70px 0 10px 0;
      .wrap-logo {
        img {
          margin-bottom: 15px; } }
      .wrap-call {
        h3 {
          font-size: 25px; } } }
    .wrap-down {
      p {
        padding: 20px 0 0 0; } } } }


