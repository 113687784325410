@import "../components/vars";

.preview-section {
  .container {
    overflow: hidden;
    .wrapper {
      .wrap-left {
        float: left;
        width: calc(60% - 30px);
        margin-right: 30px;
        padding: 45px 0 0 145px;
        position: relative;
        background: url("../img/bg/bg-top.png");
        background-repeat: no-repeat;
        background-position: 9% 22%;

        p {
          font-size: 18px;
          line-height: 1;
          color: #161616;
          font-weight: 400;
          text-transform: uppercase;
          margin-bottom: 30px; }
        .wrap-logo {
          position: relative;
          color: #ffffff;
          line-height: 1;
          font-size: 18px;
          margin-bottom: 30px;
          height: 58px; } }


      .wrap-right {
        padding: 77px 0 0 0;
        float: right;
        width: 40%;
        text-align: center;
        margin: 0 auto;
        p {
          font-size: 30px;
          text-transform: uppercase;
          color: #161616;
          line-height: 1;
          font-weight: bold;
          letter-spacing: -1px; }
        span {
          display: inline-block;
          line-height: 25px;
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 3px; } } } } }

.evoke-section {

  padding: 45px 0 40px 0;
  position: relative;
  background: url("../img/bg/bus.png") no-repeat center;
  background-size: contain;
  z-index: 1;
  &:before {
    position: absolute;
    z-index: 2;
    content: "";
    background: url("../img/bg/line.png") no-repeat center;
    top: -85px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 174px; }
  .container {
    overflow: hidden; }
  .wrapper {
    float: right;
    width: 40%;
    padding: 30px 15px;
    border: 3px solid #ffffff;
    background: rgba(0, 0, 0, 0.5);
    form {
      label {
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        color: #f9d000;
        margin-bottom: 10px; }
      .input-wrap, .select-wrap {
        width: 100%;
        border: 3px solid #182139;
        margin-bottom: 15px;
        height: 60px;
        overflow: hidden;
        input {
          width: 100%;
          height: 100%;
          padding: 0 10px;
          font-size: 18px;
          &.phone-italic {
            font-style: italic; } }
        select {
          //width: 100%
          height: 100% !important; } } }
    .btn-yellow {
      width: 100%;
      border: 3px solid #182139;
      margin-bottom: 10px;
      height: 60px;
      overflow: hidden;
      background: #f9d000;
      color: #161616;
      font-size: 22px;
      margin-top: 20px; } } }

.call-me-section {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    .wrap-left {
      background: $yellow-bg;
      padding: 20px 30px 30px 30px;
      flex: 1 1 32%;
      margin: 0 auto;
      text-align: center;
      .box {}

      .wrap-img {
        margin-bottom: 25px; }
      p {
        color: $red;
        font-size: 25px;
        line-height: 1;
        margin-bottom: 8px; }
      a {
        color: $red;
        font-size: 30px;
        line-height: 1;
        font-weight: 900;
        & > span {
          color: $red;
          font-size: 18px;
          line-height: 1; } } }

    .wrap-right {
      flex: 1 1 68%;
      background: #182139;
      padding: 20px 30px 0 30px;
      .wrap {
        margin-bottom: 30px;
        &:last-of-type {
          margin-bottom: 25px; }
        .img-wrap {
          margin-right: 20px;
          display: inline-block;
          vertical-align: middle; }
        p {
          display: inline-block;
          vertical-align: middle;
          font-size: 20px;
          line-height: 1;
          color: #ffffff; }
        span {
          color: #e1b200; } } } } }


.cost-evacuation-section {
  padding: 50px 0 20px 0;
  margin: 0 auto;
  text-align: center;
  h2 {
    color: #161616;
    margin-bottom: 10px; }
  span {
    color: #161616;
    display: inline-block;
    font-size: 25px;
    line-height: 1.1;
    margin: 0 auto 20px;
    text-align: center; }
  p {
    font-size: 25px;
    text-transform: uppercase;
    line-height: 1.1;
    margin-bottom: 30px; }
  .tabs {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 1;
      float: left;
      position: relative;
      img {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -148px;
        top: 0;
        margin: auto;
        width: 120px;
        height: 80px; }
      &.active {
        background: $yellow-bg; }
      a {
        display: block;
        padding: 25px 10px 60px 10px;
        width: 100%;
        height: 100%;
        font-size: 18px;
        color: #161616;
        text-transform: uppercase;
        &:hover {
          text-decoration: none; } } } }
  .tabs-content {
    li {
      display: none;
      &.active {
        display: block; }
      .wrapper {
        background: $yellow-bg;
        padding: 80px 80px 30px 80px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        text-align: center;
        .wrap {
          flex: 1 1 calc(50% - 40px);
          margin: 0 10px 25px;
          border: 1px solid #000000;
          padding: 15px;
          background: #ffffff;
          h3 {
            color: #161616;
            font-size: 16px;
            line-height: 1.1;
            text-transform: uppercase;
            display: inline-block;
            border-bottom: 1px solid #161616;
            margin: 0 0 30px 0; }
          .innings {
            padding-bottom: 30px;
            span {
              color: #161616;
              font-size: 16px;
              line-height: 1.1px;
              float: left;
              font-weight: bold; }
            strong {
              color: #e1b200;
              font-size: 16px;
              text-transform: uppercase;
              float: right; } }

          .value {
            padding-bottom: 30px;
            span {
              color: #161616;
              font-size: 16px;
              line-height: 1.1px;
              float: left;
              font-weight: bold; }
            strong {
              color: #e1b200;
              font-size: 16px;
              text-transform: uppercase;
              float: right; } }
          .bot {
            padding-bottom: 30px;
            span {
              color: #161616;
              vertical-align: middle;
              font-size: 16px;
              line-height: 1.1px;
              float: left;
              font-weight: bold; }
            strong {
              color: #e1b200;
              vertical-align: middle;
              font-size: 16px;
              text-transform: uppercase;
              float: right; } }
          .askclient {
            span {
              float: left;
              font-size: 12px;
              color: #161616;
              font-weight: bold;
              margin-top: -18px; } }
          .yellow {
            color: #e1b200!important; } }

        p {
          font-size: 16px;
          color: #161616;
          line-height: 1.1;
          display: block;
          margin: 0 auto 20px;
          text-transform: capitalize; } }
      h5 {
        padding: 25px 0 0 0;
        font-size: 20px;
        line-height: 1.1;
        color: #161616;
        &>span {
          color: $yellow-bg;
          font-size: 20px;
          line-height: 1.1; } } } } }
.how-we-work-section {
  background: url("../img/how-we-work-section/bg.png") no-repeat center;
  background-size: cover;
  position: relative;
  z-index: 5;
  padding: 80px 0 40px 0;
  margin: 0 auto;
  &:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }


  h2 {
    position: relative;
    color: #ffffff;
    margin-bottom: 120px; }
  .wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    .wrap {
      float: left;
      width: calc(16.5% - 10px);
      //flex: 1 1 calc(16.5% - 10px)
      text-align: center;
      margin-right: 10px;
      margin-bottom: 80px;
      .wrap-img {
        position: relative;
        text-align: center;
        margin: 0 auto 75px;
        &:before {
          content: "";
          position: absolute;
          background: url("../img/how-we-work-section/trio.png") no-repeat center;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          margin: auto;
          width: 170px;
          height: 170px;
          z-index: 2; }
        &:after {
          position: absolute;
          content: "";
          background: url("../img/how-we-work-section/arrow.png") no-repeat center;
          left: 0;
          right: -179px;
          bottom: 0;
          top: 0;
          margin: auto;
          z-index: 2; } }
      &:last-child {
        .wrap-img:after {
          display: none; } }
      h3 {

        color: #ffffff;
        font-size: 20px;
        line-height: 1;
        margin-bottom: 30px;
        text-transform: uppercase; }
      p {
        color: #ffffff;
        font-size: 14px;
        line-height: 1;
        max-width: 150px;
        text-align: center;
        margin: 0 auto; } } } }



.choice-truck-section {
  margin: 0 auto;
  text-align: center;
  padding: 80px 0 68px 0;
  position: relative;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  .container {
    h2 {
      color: #182139;
      margin-bottom: 85px; }
    .wrapper {
      max-width: 560px;
      margin: 0 auto;
      .wrap {
        width: calc(50% - 30px);
        display: inline-block;
        margin-right: 30px;
        &:hover {
          background: linear-gradient(to bottom, rgba(213,210,210,0) 0%, rgba(213,210,210,0.26) 26%, rgba(213,210,210,0.75) 75%, rgba(213,210,210,1) 100%); }
        .wrap-img {
          height: 150px; }
        .wrap-text {
          padding: 46px 0 0 0;
          height: 120px;
          span {
            color: #161616;
            font-size: 18px;
            text-transform: uppercase; } } } } } }

.advantage-section {
  position: relative;
  padding: 90px 0 50px 0;
  margin: 0 auto;
  text-align: center;
  background: #182139;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  h2 {
    color: #fff;
    margin-bottom: 5px; }
  strong {
    color: #fff;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 70px;
    text-transform: uppercase;
    display: inline-block; }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    .wrap {
      display: inline-block;
      width: 50%;
      margin: 0 auto 30px;
      .img-wrap {
        float: left;
        width: 24%; }
      .wrap-text {
        overflow: hidden;
        padding: 22px 10px;
        width: 76%;
        h3 {
          color: #ffffff;
          font-size: 20px;
          text-transform: uppercase;
          margin-bottom: 10px;
          font-weight: normal;
          &>span {
            color: #e1b200;
            font-size: 20px;
            text-transform: uppercase; } }
        p {
          font-size: 14px;
          color: #ffffff;
          line-height: 1.1; } } } } }

.help-section {
  position: relative;
  padding: 85px 0 30px 0;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  h2 {
    margin-bottom: 35px;
    color: #182139; }
  .wrap-top {
    margin-right: -30px;
    .wrap-text {
      width: calc(52% - 30px);
      margin-right: 30px;
      float: left;
      p {
        margin-bottom: 20px;
        color: #161616;
        font-size: 15px;
        line-height: 1.1; }
      strong {
        color: #182139;
        font-size: 15px;
        line-height: 1.1;
        text-transform: uppercase; } }
    .wrap-img {
      overflow: hidden;
      width: 48%; } }
  .wrap-down {
    .wrapper {
      margin-right: -30px;
      .wrap {
        width: calc(50% - 30px);
        float: left;
        margin-right: 30px;
        margin-bottom: 25px;
        img {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px; }
        &>p {
          display: inline-block;
          vertical-align: middle;
          font-size: 18px;
          color: #182139;
          max-width: 430px; } } } } }

.call-back-section {
  position: relative;
  padding: 100px 0 50px 0;
  background: #e1b200;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  .wrapper {
    margin-right: -30px;
    .wrap-text {
      width: calc(65% - 30px);
      margin-right: 30px;
      text-align: center;
      padding-top: 50px;
      p {
        font-size: 30px;
        line-height: 1.1;
        color: $red;
        margin-bottom: 20px;
        text-transform: uppercase; }
      span {
        color: $red;
        line-height: 1.1;
        font-size: 20px; } }
    .wrap-btn {
      width: calc(35% - 30px);
      overflow: hidden;
      .input-wrap {
        width: 100%;
        height: 73px;
        border: 2px solid #182139;
        margin-bottom: 20px;
        input {
          width: 100%;
          height: 100%;
          font-size: 20px;
          padding: 0 10px; } } } } }

.reviews-section {
  position: relative;
  padding: 85px 0 50px 0;
  &:after {
    height: 34px;
    background: url("../img/how-we-work-section/lin.png");
    background-repeat: repeat-x;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2; }
  h2 {
    color: #182139;
    margin-bottom: 35px; }
  .slider {
    padding: 80px 0 0 0;
    margin-right: -30px;
    .slide {
      width: calc(33.3% - 30px);
      margin-right: 30px;
      .wrapper {
        background: $yellow-bg;
        padding: 15px;
        .img-wrap {
          display: block;
          margin-bottom: 20px; }
        p {
          font-size: 14px;
          color: #161616;
          line-height: 1.1;
          height: 150px;
          overflow: hidden;
          margin-bottom: 5px; }
        span {
          font-size: 16px;
          color: #161616;
          line-height: 1.1; } } } } }


@media only screen and (max-width: 1000px) {
  .cost-evacuation-section {
    padding: 30px 0 20px 0;
    h2 {
      font-size: 25px; } }
  .tabs {
    li {
      a {
        padding: 15px 5px 30px 5px; }
      img {
        display: none; } } }
  .tabs-content {
    li {
      .wrapper {
        .wrap {
          flex: 1 1 100%!important; } } } } }




@media only screen and (max-width: 1100px) {
  .help-section {
    .wrap-top {
      margin: 0 auto;
      .wrap-text {
        width: 100%;
        margin: 0 auto 20px;
        text-align: center; }

      .wrap-img {
        width: 100%;
        margin: 0 auto;
        text-align: center; } }

    .wrap-down {
      .wrapper {
        margin: 0 auto;
        .wrap {
          margin: 0 auto 20px;
          width: 100%;
          img {
            display: block;
            margin: 0 auto 20px;
            text-align: center; }
          p {
            display: block;
            text-align: center;
            margin: 0 auto;
            max-width: auto; } } } } } }
@media only screen and (max-width: 1029px) {
  .preview-section {
    .container {
      .wrapper {
        margin: 0 auto;
        text-align: center;
        .wrap-left {
          background-position: 53% 50%;
          width: 100%; }
        .wrap-right {
          width: 100%;
          padding: inherit;
          margin-bottom: 10px; } } } } }

@media only screen and (max-width: 1150px) {
  .how-we-work-section {
    .wrapper {
      margin: 0 auto -10px;
      .wrap {
        width: calc(33.3% - 10px); } } } }

@media only screen and (max-width: 1075px) {
  .preview-section {
    .container {
      .wrapper {
        .wrap-left {
          background-position: 20% 50%; } } } } }

@media only screen and (max-width: 1000px) {
  .call-me-section {
    .wrapper {
      .wrap-left {
        flex: 1 1 100%;
        padding: 20px 30px 20px 30px; }
      .wrap-right {
        flex: 1 1 100%;
        margin: 0 auto;
        text-align: center;
        .img-wrap {
          display: block !important;
          margin: 0 0 10px 0 !important; } } } } }

@media only screen and (max-width: 600px) {
  .cost-evacuation-section {
    .tabs-content {
      li {
        .wrapper {
          padding: 5px;
          .wrap {
            .innings {
              span {
                font-size: 12px; } }
            .value {
              span {
                font-size: 12px; } }
            .bot {
              span {
                font-size: 9px;
                padding-top: 10px; } } } } } } } }

@media only screen and (max-width: 600px) {
  .cost-evacuation-section {
    .tabs {
      li {
        flex: 1 1 100%;
        a {
          padding: 25px 10px 37px 10px; } } }
    .tabs-content {
      li {}
      .wrapper {
        .wrap {
          .innings {
            strong {
              font-size: 12px!important; } }
          .value {
            strong {
              font-size: 12px!important; } }
          .bot {
            strong {
              font-size: 12px!important; } } } } } } }



@media only screen and (max-width: 970px) {
  .advantage-section {
    .wrapper {
      .wrap {
        width: 100%; } } } }
@media only screen and (max-width: 970px) {
  .evoke-section {
    .wrapper {
      width: 100%; } } }


@media only screen and (max-width: 770px) {
  .how-we-work-section {
    h2 {
      margin: 0 0 100px 0;
      font-size: 25px; }
    .wrapper {
      margin: 0 auto -10px;
      .wrap {
        width: calc(50% - 10px); } } } }

@media only screen and (max-width: 750px) {
  .call-back-section {
    padding: 50px 0 30px;
    .wrapper {
      margin: 0 auto;
      .wrap-text {
        width: 100%;
        margin: 0 auto 20px;
        padding: inherit;
        p {
          font-size: 25px; } }
      .wrap-btn {
        width: 100%;
        margin: auto; } } } }






@media only screen and (max-width: 600px) {
  .choice-truck-section {
    padding: 80px 0 30px 0;
    h2 {
      font-size: 25px; }
    .container {
      .wrapper {
        .wrap {
          width: 100%;
          margin: 0 auto 30px; } } } }
  .advantage-section {
    h2 {
      font-size: 25px; }
    strong {
      font-size: 15px; } }
  .help-section {
    h2 {
      font-size: 25px; } }
  .reviews-section {
    h2 {
      font-size: 25px; } } }


@media only screen and (max-width: 570px) {
  .how-we-work-section {
    .wrapper {
      margin: 0 auto -10px;
      .wrap {
        width: 100%;
        .wrap-img {
          &:after {
            display: none; } } } } } }


@media only screen and (max-width: 570px) {
  .advantage-section {
    .wrapper {
      .wrap {
        .img-wrap {
          float: none;
          text-align: center;
          margin: 0 auto; }
        .wrap-text {
          text-align: center;
          margin: 0 auto;
          width: 100%; } } } } }










@media only screen and (max-width: 660px) {
  .preview-section {

    .container {
      .wrapper {
        margin: 0 auto;
        text-align: center;
        .wrap-left {
          background: inherit;
          width: 100%;
          text-align: center;

          padding: 20px 0 0 0;
          .wrap-logo {
            color: #2b2b2b;
            margin: inherit; } }
        .wrap-right {
          p {
            font-size: 25px; }
          span {
            font-size: 15px; } } } } }


 }  //.select2-container--default .select2-selection--single .select2-selection__arrow b




@media only screen and (max-width: 400px) {
  .call-me-section {
    .wrapper {
      .wrap-left {
        p {
          font-size: 16px !important; } } } }

  .evoke-section {
    background-size: cover; } }

