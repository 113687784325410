/*Components*/
@import "components/fonts";
@import "components/vars";
@import "components/reset";
@import "components/ui";


/*Header and footer*/
@import "components/header";
@import "components/footer";

/*Page's style*/
@import "pages/index";
